import request from '@/util/axios.js'

// login
export function login(data) {
  return request({
    url: '/api/customer/login',
    method: 'post',
    data
  })
}

// 获取验证码
export function getCaptcha() {
  return request({
    url: '/api/dmz/random/captcha',
    method: 'get'
  })
}

// 省市区
export function getDistrict(params) {
  return request({
    url: '/api/dmz/district/childrens',
    method: 'get',
    params
  })
}

// 统计
export function getStatisticsMonth(params) {
  return request({
    url: '/api/dmz/thoracalgia/stat/rows',
    method: 'get',
    params
  })
}
//机构数

export function getOrgans(params) {
  return request({
    url: '/api/dmz/dmz/stats',
    method: 'get',
    params
  })
}
// 机构等级
export function getOrganizationLevel() {
  return request({
    url: '/api/dmz/dict/findhospitalgrade',
    method: 'get'
  })
}

// 医院列表
export function getOrganizationList(params) {
  return request({
    url: '/api/dmz/organization/list',
    method: 'get',
    params,
  })
}

// 胸痛中心注册
export function postOrganizationRegister(data) {
  return request({
    url: '/api/dmz/organization/register/add',
    method: 'post',
    data,
  })
}
//胸痛中心修改
export function postOrganizationEdit(data) {
  return request({
    url: '/api/dmz/organization/register/modify',
    method: 'post',
    data,
  })
}

// 获取机构信息
export function getOrganDetail(id) {
  return request({
    url: `/api/organization/register/${id}`,
    method: 'get'
  })
}

// 上传材料
export function postCertifiedUpload(data) {
  return request({
    url: '/api/organization/register/certified',
    method: 'post',
    data,
  })
}

// 发起认证
export function postCertifiedApply(id) {
  return request({
    url: `/api/organization/register/certified/${id}`,
    method: 'post'
  })
}

// 文件删除
export function deleteCertifiedFile(params) {
  return request({
    url: '/api/customer/organization/register/attestation/file/remove',
    method: 'delete',
    params,
  })
}


// 医生注册
export function postDoctorRegister(data) {
  return request({
    url: '/api/dmz/doctor/register',
    method: 'post',
    data,
  })
}

// 学历
export function getEducation() {
  return request({
    url: '/api/dmz/dict/education',
    method: 'get'
  })
}